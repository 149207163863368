import React from "react";
// import Beef from "./Beef.png";
import { RouteComponentProps } from "react-router-dom";

import {
  NoResultWrapper,
  ImageWrapper,
  Image,
  NoResultMsg
} from "./NotFound.style";

type NoResultProps = {
  id?: string;
  style?: any;
};

const NoResult: React.FC<NoResultProps & RouteComponentProps> = ({ id, style }) => {
  return (
    <NoResultWrapper id={id} style={style}>
      <NoResultMsg>404... Page non trouvée !</NoResultMsg>

      <ImageWrapper>
        {/* <Image src={Beef} alt="Not Found" /> */}
      </ImageWrapper>
    </NoResultWrapper>
  );
};

export default NoResult;
