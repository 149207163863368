import React, { useContext, lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; // Remplace Switch, Redirect
import {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PRODUCTS,
  CATEGORY,
  DASHBOARD,
  ORDERS,
  ORDER_FORM,
  ORDER_FORM_MODIFY,
  SALE_LOCATIONS,
  SETTINGS,
  CUSTOMERS,
  MONTHS,
  COUPONS,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  LABELS,
  LABEL_PDF,
  ORDER_PDF,
  ORDERS_PDF,
  LOTS,
  ORDERED_ARTICLES,
  ORDERED_ARTICLES_PDF,
} from 'settings/constants';
import AuthProvider, { AuthContext } from 'context/auth';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import NotFound from 'containers/NotFound/NotFound';

const Products = lazy(() => import('containers/Products/Products'));
const AdminLayout = lazy(() => import('containers/Layout/Layout'));
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'));
const Category = lazy(() => import('containers/Category/Category'));
const Orders = lazy(() => import('containers/Orders/Orders'));
const OrderForm = lazy(() => import('containers/OrderForm/OrderForm'));
const OrderFormModify = lazy(() => import('containers/OrderForm/OrderFormModify'));
const SaleLocations = lazy(() => import('containers/SaleLocations/SaleLocations'));
const Labels = lazy(() => import('containers/Labels/Labels'));
const ProductLabelPdf = lazy(() => import('containers/Pdf/ProductLabelPdf.js'));
const OrderPdf = lazy(() => import('containers/Pdf/OrderPdf.js'));
const SelectedOrdersPdf = lazy(() => import('containers/Pdf/SelectedOrdersPdf.js'));
const OrderedArticlesPdf = lazy(() => import('containers/Pdf/OrderedArticlesPdf.js'));
const Lots = lazy(() => import('containers/Lots/Lots'));
const OrderedArticles = lazy(() => import('containers/OrderedArticles/OrderedArticles'));
const Settings = lazy(() => import('containers/Settings/Settings'));
const SiteSettingForm = lazy(() => import('containers/SiteSettingForm/SiteSettingForm'));
const StaffMembers = lazy(() => import('containers/StaffMembers/StaffMembers'));
const Customers = lazy(() => import('containers/Customers/Customers'));
const Months = lazy(() => import('containers/Months/Months'));
const Coupons = lazy(() => import('containers/Coupons/Coupons'));
const Login = lazy(() => import('containers/Login/Login'));
const ForgotPassword = lazy(() => import('containers/Login/ForgotPassword'));
const ResetPassword = lazy(() => import('containers/Login/ResetPassword'));

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated, checkToken } = useContext(AuthContext);
  checkToken();
  return isAuthenticated ? (
    children
  ) : (
    <Navigate
      to="/login"
      state={{ from: rest.location }}
      replace
    />);
}

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Routes>
          <Route
            path={DASHBOARD}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Dashboard />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={PRODUCTS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Products />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={LOTS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Lots />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={CATEGORY}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Category />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={ORDERS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Orders />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={ORDER_FORM}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <OrderForm />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={ORDER_FORM_MODIFY}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <OrderFormModify />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={SALE_LOCATIONS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <SaleLocations />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={CUSTOMERS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Customers />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={MONTHS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Months />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={COUPONS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Coupons />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={ORDERED_ARTICLES}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <OrderedArticles />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={SETTINGS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Settings />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={STAFF_MEMBERS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <StaffMembers />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={SITE_SETTINGS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <SiteSettingForm />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={LABELS}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <Labels />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={LABEL_PDF}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <ProductLabelPdf />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={ORDER_PDF}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <OrderPdf />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={ORDERED_ARTICLES_PDF}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <OrderedArticlesPdf />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path={ORDERS_PDF}
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Suspense fallback={<InLineLoader />}>
                    <SelectedOrdersPdf />
                  </Suspense>
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route path={LOGIN} element={<Login />} />
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={RESET_PASSWORD} element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} /> {/* Route catch-all pour NotFound */}
        </Routes>
      </Suspense>
    </AuthProvider>
  );
};

export default AppRoutes;